import React from "react";
import { isChrome, isFirefox, isIeEdge, isSafari, getBrowser } from "@tightrope/lpscripts/browserdetect";
import Styles from "./css/bmpagetiles.module.scss";
import Data from "./data/bmpagetiles.json";

const images = [
    '/img/carousel1.png',
    '/img/carousel2.png',
    '/img/carousel3.png',
    '/img/carousel4.png',
    '/img/carousel5.png',
    '/img/carousel6.png',
];

class bmPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: "none",
      pageData: props.data,
      data: Data,
      isLoading: true
    };
  }

  showBrowsers(browsers) {
    let display = false;
    if (getBrowser() === "ie" && browsers.includes("ie")) {
      display = true;
    }
    else if (getBrowser() === "firefox" && browsers.includes("firefox")) {
      display = true;
    }
   else if (getBrowser() === "chrome" && browsers.includes("chrome")) {
      display = true;
    }
   else if (getBrowser() === "edge" && browsers.includes("edge")) {
      display = true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
   else if (getBrowser() === "other" && browsers.includes("other")) {
      display = true;
    }
    if (display) this.setState({ display: "block" });
    else this.setState({ display: "none" });
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.data.then((data) => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData });
        this.state.isChrome = isChrome();
        this.state.isFirefox = isFirefox();
        this.state.isIeEdge = isIeEdge();
        this.state.isSafari = isSafari();

        if (isChrome()) {
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({ data: browserData });
        }
        if (isFirefox()) {
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({ data: browserData });
        }
        if (isSafari()) {
          const browserData = Object.assign(this.state.data, this.state.data.safari);
          this.setState({ data: browserData });
        }
        if (isIeEdge()) {
          const browserData = Object.assign(this.state.data, this.state.data.edge);
          this.setState({ data: browserData });
        }
        if (this.props.browsers) {
          this.showBrowsers(this.props.browsers);
        } else {
          this.setState({ display: "block" });
        }
      });
    }
}

  render() {
    return (
      <>
      <div id={Styles.bmPagetiles} style={{ display: this.state.display }}>
        <main>
          <header>  </header>
          <div className={Styles.imageGrid}>
            {images.map((src, index) => (
              <img key={index} src={src} alt={`Carousel image ${index + 1}`} />
            ))}
          </div>
          <div className = {Styles.middle}>
            <img className = {Styles.logo} src={this.state.data.logo} />
            <h1 className = {`hc_param ${Styles.headline}`}>{this.state.data.headline}</h1>
            <ul className={Styles.bulletPoints}>
              {this.state.data.bulletPoints.map((bullet, i) => (
                <li key={i}>{bullet}</li>
              ))}
            </ul>
            {this.props.beforeButton}
            <button className={`continue cl1`} data-cy="cta" onClick = {() => {if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) return; else return window.triggerInstall()} }
              dangerouslySetInnerHTML={{ __html: this.state.data.cta }}></button>
            {this.props.children}
          </div>
          <ul className = {Styles.steps}>
            {this.state.data.steps != undefined ? this.state.data.steps.map((bullet, i) => {
              return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
            }) : null}
          </ul>
          {this.props.footer}
        </main>
      </div>
      </>
    );
  }
}

export default bmPage;
